



























































































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import validator from "validator";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const { convertToSlug, goBack } = useMisc({ root });
    const model = reactive({
      name: "",
      url: "",
      alias: "",
      type: "anonymous",
      notificationAboutCompletion: false,
      notificationMail: "",
      aboveInfo: "",
      successInfo: "",
      status: true,
      participantsIds: [] as string[],
    });

    const state = reactive({
      valid: false,
      loading: false,
      error: false as unknown,
      success: false,
      participants: [],
    });

    watch(
      () => model.name,
      () => {
        model.alias = convertToSlug(model.name);
      }
    );

    const fetchParticipants = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant`)
        .then(({ data: { participants } }) => {
          state.participants = participants
            .map((el: any) => ({
              id: el.id,
              name: `${el.degree} ${el.firstName} ${el.lastName} (${el.code})`,
              firstName: el.firstName,
              lastName: el.lastName,
              degree: el.degree,
              code: el.code,
              state: el.state,
            }))
            .filter((i: any) => i.state === "participant");
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.participants = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchParticipants);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("event.panel.modules.surveyModule.error409")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        url: model.url,
        alias: model.alias,
        type: model.type,
        notificationAboutCompletion: model.notificationAboutCompletion,
        notificationMail: model.notificationAboutCompletion
          ? model.notificationMail
          : undefined,
        informationAboveSurvey: model.aboveInfo || undefined,
        informationAfterCompletion: model.successInfo || undefined,
        isEnabled: model.status,
        participantsIds: model.participantsIds || null,
      };

      state.loading = true;

      axiosInstance
        .post(`event/${root.$route.params.id}/survey`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          model.name = "";
          model.url = "";
          model.alias = "";
          model.type = "anonymous";
          model.notificationAboutCompletion = false;
          model.notificationMail = "";
          model.aboveInfo = "";
          model.successInfo = "";
          model.status = true;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("event.panel.modules.surveyModule.addSuccess"),
          });

          setTimeout(() => {
            goBack("panel.event.view.surveyModule.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      isRequiredUrl: [
        (v: string) =>
          (!!v && validator.isURL(v, { require_protocol: true })) ||
          root.$tc("layout.misc.validUrl"),
      ],
    };

    const selectAll = () => {
      model.participantsIds = state.participants.map((el: any) => el.id);
    };

    return { model, state, onSubmit, rules, goBack, selectAll };
  },
});
